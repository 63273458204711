import React from 'react'
import { Accordion, Card, Button, Col, Row } from 'react-bootstrap'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { gaCreateEvent } from '../utilities/Analytics'
import { PortableText } from '@portabletext/react'

/**
 * @param {string} question
 * @param {string} answer
 * @param {string|undefined} slug to be passed as id (for example, for anchoring)
 * @returns {JSX.Element}
 * @constructor
 */
const FaqItem = ({ question, answer, slug = undefined }) => {
    const onClickHandler = () => gaCreateEvent('Click', 'Faq item clicked', slug || question)

    return (
        <StyledAccordion className='mb-4' {...schemaQuestionAttrs} id={slug}>
            <Card className='px-4 pb-0 shadow-sm'>
                <Accordion.Toggle
                    as={Button}
                    variant='link'
                    className='px-0 mt-0 text-left'
                    eventKey='0'
                    itemProp='name'
                    onClick={onClickHandler}>
                    <Row>
                        <Col>{question}</Col>
                        <Col xs='1' className='text-right'>
                            <FontAwesomeIcon icon={faChevronDown} />
                        </Col>
                    </Row>
                </Accordion.Toggle>
            </Card>
            <Accordion.Collapse eventKey='0' {...schemaAnswerAttrs}>
                <div className='pt-4 pb-1 px-4'>
                    <PortableText value={answer} />
                </div>
            </Accordion.Collapse>
        </StyledAccordion>
    )
}

const StyledAccordion = styled(Accordion)`
    .card {
        border-radius: 2rem !important;
        border: 1px solid var(--gray) !important;
    }
    .card:hover {
        border-color: var(--green) !important;
    }

    .btn-link {
        color: var(--black);
        font-weight: 300;
        letter-spacing: 2px;
        font-size: 1.1rem;
    }
    .btn-link:focus {
        box-shadow: none;
        text-decoration: none;
    }
    .btn-link:hover {
        text-decoration: none;
        color: var(--green);
    }
`

const schemaQuestionAttrs = {
    itemScope: '',
    itemProp: 'mainEntity',
    itemType: 'https://schema.org/Question',
}

const schemaAnswerAttrs = {
    itemScope: '',
    itemProp: 'acceptedAnswer',
    itemType: 'https://schema.org/Answer',
}

export default FaqItem
