import React from 'react'
import { Container } from 'react-bootstrap'
import { graphql, useStaticQuery } from 'gatsby'
import FaqItem from '../FaqItem'

/**
 * @param item
 * @returns {JSX.Element}
 * @constructor
 */
const FaqCategorySection = ({ item }) => {
    const { allFaqItems } = useStaticQuery(graphql`
        query {
            allFaqItems: allSanityFaqItem(sort: { fields: weight, order: ASC }) {
                nodes {
                    question
                    _rawAnswer(resolveReferences: { maxDepth: 1 })
                    slug {
                        current
                    }
                    weight
                    category {
                        _id
                    }
                }
            }
        }
    `)

    // Get faq items belonging to this category
    const faqItems = allFaqItems.nodes.filter(faqItem =>
        faqItem.category.some(category => category._id === item.category._id)
    )

    return (
        <section className='py-3'>
            <Container>
                <h2 id={item.category.slug.current} className='mt-4 mb-5'>
                    {item.category.name}
                </h2>

                {faqItems.map(item => (
                    <FaqItem
                        key={item.slug.current}
                        question={item.question}
                        answer={item._rawAnswer}
                        slug={item.slug.current}
                    />
                ))}
            </Container>
        </section>
    )
}

export default FaqCategorySection
