import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { PortableText } from '@portabletext/react'
import styled from 'styled-components'

/**
 * A component for rendering a sanity richTextBlock
 *
 * @TODO: Handle internal/external links
 *
 * @param item
 * @returns {JSX.Element}
 * @constructor
 */
const RichTextSection = ({ item }) => {
    return (
        <section className='mb-4'>
            <RichTextSectionStyles>
                <Container>
                    <Row className='justify-content-center'>
                        <Col lg='8'>
                            <PortableText key={item._key} value={item._rawContent} />
                        </Col>
                    </Row>
                </Container>
            </RichTextSectionStyles>
        </section>
    )
}

const RichTextSectionStyles = styled.article`
    h2,
    .h2,
    h3,
    .h3 {
        margin-top: 3rem;
    }
`

export default RichTextSection
