import React, { useState } from 'react'
import { Alert, Button, Col, Form, Row } from 'react-bootstrap'
import NetlifiedForm from './NetlifiedForm'

/**
 * @param {string} name
 * @returns {JSX.Element}
 * @constructor
 */
const ContactForm = ({ name }) => {
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(false)
    const [submitted, setSubmitted] = useState(false)

    const submitHandler = e => {
        setError(false)
        setLoading(true)

        let formData = new FormData(e.target)

        fetch('/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: new URLSearchParams(formData).toString(),
        })
            .then(response => {
                if (!response.ok) {
                    setError('Oh no, there was an error submitting this form. Please try again later!')
                    return
                }

                setSubmitted(true)
            })
            .catch(() => setError('Oh no, there was an error submitting this form. Please try again later!'))
            .finally(() => setLoading(false))
    }

    return !submitted ? (
        <>
            {error && <Alert variant='danger'>{error}</Alert>}
            <NetlifiedForm
                onSubmit={submitHandler}
                className='mb-5'
                name={name}
                subject='Contact request - Siru Mobile'>
                <Form.Group controlId='name'>
                    <Form.Label srOnly>Name</Form.Label>
                    <Form.Control type='text' placeholder='Name' name='name' className='px-4' />
                </Form.Group>
                <Form.Group controlId='phone'>
                    <Form.Label srOnly>Phone</Form.Label>
                    <Form.Control type='text' placeholder='Phone' name='phone' className='px-4' />
                </Form.Group>
                <Form.Group controlId='email'>
                    <Form.Label srOnly>Email</Form.Label>
                    <Form.Control type='email' placeholder='Email' name='email' className='px-4' />
                </Form.Group>
                <Form.Group controlId='message'>
                    <Form.Label srOnly>Message</Form.Label>
                    <Form.Control
                        as='textarea'
                        rows={3}
                        placeholder='Message'
                        name='message'
                        className='px-4'
                        required
                    />
                </Form.Group>

                <Row className='d-flex justify-content-end'>
                    <Col lg='5'>
                        <Button variant='success' type='submit' block disabled={loading} className='text-uppercase'>
                            Send
                        </Button>
                    </Col>
                </Row>
            </NetlifiedForm>
        </>
    ) : (
        <div className='text-center'>
            <span className='h1 mb-3 d-block'>Thank you!</span>
            <p className='mb-4'>Our team received your message and will take a look at it soon.</p>
        </div>
    )
}

export default ContactForm
