import React from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import { Card, Col, Row } from 'react-bootstrap'
import styled from 'styled-components'

/**
 * @param item
 * @returns {JSX.Element}
 * @constructor
 */
const NewsListSection = ({ item }) => {
    const { allNewsArticles } = useStaticQuery(graphql`
        query {
            allNewsArticles: allSanityNewsArticle(sort: { fields: _createdAt, order: DESC }) {
                nodes {
                    _createdAt(formatString: "D.M.YYYY")
                    _key
                    id
                    title
                    subtitle
                    _rawBody(resolveReferences: { maxDepth: 1 })
                    slug {
                        current
                    }
                }
            }
        }
    `)
    // Limit item count if necessary
    let newsToDisplay
    if (item.amountOfNewsToDisplay) {
        newsToDisplay = allNewsArticles.nodes.slice(0, item.amountOfNewsToDisplay)
    } else {
        newsToDisplay = allNewsArticles.nodes
    }

    return (
        <section id={item._key} className='pb-4 pt-3'>
            <div className='container pt-3 pb-3'>
                <Row className='justify-content-center'>
                    <Col md='10'>
                        {item.title && <h2 className='mt-3'>{item.title}</h2>}
                        <NewsGridStyles>
                            {newsToDisplay.map(item => (
                                <Card key={item.id} className='mb-3 px-3 pt-2'>
                                    <Card.Body className='pb-2'>
                                        <Card.Title>{item.title}</Card.Title>
                                        <Card.Subtitle className='mb-2 text-muted font-italic small'>
                                            {item._createdAt}
                                        </Card.Subtitle>
                                        <Card.Text>{truncate(blocksToText(item._rawBody))}</Card.Text>
                                        <p>
                                            <Link to={`/home/news/${item.slug.current}`}>Read more</Link>
                                        </p>
                                    </Card.Body>
                                </Card>
                            ))}
                        </NewsGridStyles>
                        {item.amountOfNewsToDisplay !== 0 && (
                            <p className='text-right'>
                                <Link to='/home/news'>See all news</Link>
                            </p>
                        )}
                    </Col>
                </Row>
            </div>
        </section>
    )
}

export default NewsListSection

const blocksToText = blocks =>
    blocks
        .filter(blk => blk._type === 'block')
        .map(block => block.children.map(child => child.text).join(''))
        .join('\n\n')

const truncate = (string, limit = 80) => {
    if (string.length <= limit) {
        return string
    }
    return `${string.slice(0, limit)}...`
}

const NewsGridStyles = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 2em;
`
