import React from 'react'
import { Col, Container, Row, Table } from 'react-bootstrap'

/**
 * @param item
 * @returns {JSX.Element}
 * @constructor
 */
const TableSection = ({ item }) => {
    // We assume the first row is a heading
    const [heading, ...rows] = item.chart.rows

    return (
        <section>
            <Container>
                <Row className='justify-content-center mb-3'>
                    <Col lg='8'>
                        <Table striped bordered>
                            <thead>
                                <tr>
                                    {heading.cells.map((cell, index) => (
                                        <td key={index}>{cell}</td>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {rows.map(row => (
                                    <tr key={row._key}>
                                        {row.cells.map((cell, index) => (
                                            <td key={index}>{cell}</td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default TableSection
