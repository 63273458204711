import React from 'react'
import BackgroundImage from 'gatsby-background-image'
import { Col, Container, Jumbotron, Row } from 'react-bootstrap'
import { scroller } from 'react-scroll'
import { convertToBgImage } from 'gbimage-bridge'

/**
 * @param item
 * @returns {JSX.Element}
 * @constructor
 */
const FullScreenHeroSection = ({ item }) => {
    const ctaClickHandler = e => {
        // Handle a smooth scroll if the target is an element id
        if (item.buttonTarget?.startsWith('#')) {
            e.preventDefault()
            const sectionId = item.buttonTarget.substring(1)
            scroller.scrollTo(sectionId, {
                smooth: 'easeInOutQuart',
                offset: -45,
            })
        }
    }

    const image = item.bgImage.asset.gatsbyImageData
    const bgImage = convertToBgImage(image)

    return (
        <BackgroundImage Tag='section' className='darker-background-overlay py-2' {...bgImage} preserveStackingContext>
            <Jumbotron fluid className='text-white mb-0 d-flex align-items-center text-center full-screen-jumbo'>
                <Container>
                    <Row className='d-flex justify-content-center'>
                        <Col xl='7' lg='7' md='9' sm='11'>
                            <p className='message pb-3'>{item.title}</p>
                            <p className='mb-4 pb-3 spaced-light'>{item.text}</p>

                            {item.buttonText && (
                                <a
                                    href={item.buttonTarget}
                                    className='btn btn-success btn-lg px-5 rounded-0'
                                    onClick={ctaClickHandler}>
                                    {item.buttonText}
                                </a>
                            )}
                        </Col>
                    </Row>
                </Container>
            </Jumbotron>
        </BackgroundImage>
    )
}

export default FullScreenHeroSection
