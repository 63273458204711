import React from 'react'
import { graphql } from 'gatsby'
import SEOTools from '../components/SEOTools'
import { Col, Row } from 'react-bootstrap'
import HeroUnit from '../components/HeroUnit'
import RichTextSection from '../components/pageBuilderComponents/RichTextSection'
import Layout from '../components/Layout'
import FaqCategorySection from '../components/pageBuilderComponents/FaqCategorySection'
import TableSection from '../components/pageBuilderComponents/TableSection'
import ContactFormSection from '../components/pageBuilderComponents/ContactFormSection'
import NumberedSectionWithBg from '../components/pageBuilderComponents/NumberedSectionWithBg'
import NumberedSection from '../components/pageBuilderComponents/NumberedSection'
import FullScreenHeroSection from '../components/pageBuilderComponents/FullScreenHeroSection'
import NewsListSection from '../components/pageBuilderComponents/NewsListSection'

/**
 * @TODO add hero type component
 *
 * @param data
 * @returns {JSX.Element}
 * @constructor
 */
export default function SanityPage({ data }) {
    const { page } = data

    const firstPageComponentIsAHero = page.pageBuilder[0]?._type === 'fullScreenHeroSection'

    return (
        <Layout>
            <SEOTools title={page.title} description={page.description} />

            {/* If page does not yet have a hero section, create it */}
            {!firstPageComponentIsAHero && (
                <HeroUnit backgroundImage={data.heroBg} className='darker-background-overlay'>
                    <Row className='d-flex align-items-center justify-content-center text-center my-5'>
                        <Col md='8'>
                            <h1>{page.title}</h1>
                        </Col>
                    </Row>
                </HeroUnit>
            )}

            {page.pageBuilder.map(item => {
                switch (item._type) {
                    case 'richTextBlock':
                        return <RichTextSection key={item._key} item={item} />
                    case 'faqCategorySection':
                        return <FaqCategorySection key={item._key} item={item} />
                    case 'tableSection':
                        return <TableSection key={item._key} item={item} />
                    case 'contactFormSection':
                        return <ContactFormSection key={item._key} item={item} />
                    case 'numberedSectionWithBg':
                        return <NumberedSectionWithBg key={item._key} item={item} />
                    case 'numberedSection':
                        return <NumberedSection key={item._key} item={item} />
                    case 'fullScreenHeroSection':
                        return <FullScreenHeroSection key={item._key} item={item} />
                    case 'newsArticleListSection':
                        return <NewsListSection key={item._key} item={item} />
                    default:
                        return null
                }
            })}
        </Layout>
    )
}

export const query = graphql`
    query ($slug: String!) {
        page: sanityPage(slug: { current: { eq: $slug } }) {
            title
            description
            pageBuilder {
                ... on SanityFaqCategorySection {
                    _key
                    _type
                    category {
                        # Note: Would be cool to be able to query immediately the faq items belonging to the category
                        _id
                        name
                        slug {
                            current
                        }
                    }
                }
                ... on SanityRichTextBlock {
                    _key
                    _type
                    _rawContent(resolveReferences: { maxDepth: 1 })
                }
                ... on SanityTableSection {
                    _key
                    _type
                    chart {
                        rows {
                            _key
                            cells
                        }
                    }
                }
                ... on SanityContactFormSection {
                    _key
                    _type
                    formName {
                        current
                    }
                }
                ... on SanityNewsArticleListSection {
                    _key
                    _type
                    title
                    amountOfNewsToDisplay
                }
                ... on SanityNumberedSectionWithBg {
                    _key
                    _type
                    number
                    title
                    text
                    buttonText
                    buttonTarget
                    sectionIdSlug {
                        current
                    }
                    bgImage {
                        asset {
                            gatsbyImageData(placeholder: BLURRED, width: 3840)
                        }
                    }
                }
                ... on SanityNumberedSection {
                    _key
                    _type
                    number
                    title
                    text
                    buttonText
                    buttonTarget
                    sectionIdSlug {
                        current
                    }
                }
                ... on SanityFullScreenHeroSection {
                    _key
                    _type
                    title
                    text
                    buttonText
                    buttonTarget
                    bgImage {
                        asset {
                            gatsbyImageData(placeholder: BLURRED, width: 3840)
                        }
                    }
                }
            }
        }
        heroBg: file(relativePath: { eq: "sirumobile_frontpage_hero.jpg" }) {
            childImageSharp {
                gatsbyImageData(
                    width: 3840
                    height: 500
                    placeholder: BLURRED
                    quality: 80
                    transformOptions: { cropFocus: CENTER, fit: OUTSIDE }
                )
            }
        }
    }
`
