import React from 'react'
import { Form } from 'react-bootstrap'
import { useLocation } from '@reach/router'
import { gaCreateEvent } from '../../utilities/Analytics'

/**
 * NetlifiedForm
 *
 * A form which is automatically detected by netlify, which handles submissions for us. Adds necessary
 * fields and props, and a honeypot-field.
 *
 * @param {JSX.Element} children
 * @param {function} onSubmit
 * @param {string} name Of the form
 * @param {string} subject To be used in netlify form notifications
 * @param restProps
 * @returns {JSX.Element}
 * @constructor
 */
const NetlifiedForm = ({ children, onSubmit = () => {}, name, subject, ...restProps }) => {
    const location = useLocation()

    const submitHandler = e => {
        e.preventDefault()
        onSubmit(e)
        gaCreateEvent('Form', 'Submit', name)
    }

    return (
        <Form data-netlify='true' netlify-honeypot='skynet-pot' name={name} onSubmit={submitHandler} {...restProps}>
            {/* Netlify requires this hidden form name field */}
            <input type='hidden' name='form-name' value={name} />

            {/* Honeypot for catching those pesky bots */}
            <Form.Group controlId='faqContact.skynet-pot' className='d-none'>
                <Form.Label srOnly>Do not fill this out if you’re human:</Form.Label>
                <Form.Control type='text' name='skynet-pot' />
            </Form.Group>

            {children}

            {/* Nice to know, from what page the form was sent */}
            <input type='hidden' name='sent-from-page' value={location.href} />
            {/* Used in netlify form notifications, like emails, etc */}
            {subject && <input type='hidden' name='subject' value={subject} />}
        </Form>
    )
}

export default NetlifiedForm
