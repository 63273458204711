import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import ContactForm from '../Forms/ContactForm'
import StyledSideCol from '../styled/StyledSideCol'

/**
 * @param item
 * @returns {JSX.Element}
 * @constructor
 */
const ContactFormSection = ({ item }) => {
    return (
        <section id='contact-form' className='pb-5 pt-3'>
            <Container>
                <Row className='mb-4 pt-5'>
                    <StyledSideCol md='3' className='mb-3'>
                        <div className='text-secondary top'>Contact us</div>
                        <div className='h3 bottom'>
                            <strong>Ask a question</strong>
                        </div>
                    </StyledSideCol>
                    <Col>
                        <p>
                            Questions, concerns, or feedback? Would like more information on something? Let us know how
                            we can help you and we will get back to you.
                        </p>
                    </Col>
                </Row>

                <ContactForm name={item.formName.current} />
            </Container>
        </section>
    )
}

export default ContactFormSection
