import React from 'react'
import NumberedRow from '../Layout/NumberedRow'
import { Link } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import { convertToBgImage } from 'gbimage-bridge'

/**
 * @param item
 * @returns {JSX.Element}
 * @constructor
 */
const NumberedSectionWithBg = ({ item }) => {
    const image = item.bgImage.asset.gatsbyImageData
    const bgImage = convertToBgImage(image)

    return (
        <BackgroundImage
            id={item.sectionIdSlug?.current || item._key}
            Tag='section'
            className='darker-background-overlay py-2'
            {...bgImage}
            preserveStackingContext>
            <div className='container pt-3 pb-5 text-white'>
                <NumberedRow number={item.number} numberContainerClassName='bg-success text-white border-0'>
                    <h2 className='h1 mb-4'>{item.title}</h2>

                    <p className='mb-4 spaced-light'>{item.text}</p>

                    {item.buttonText && (
                        <div className='d-grid gap-2 d-md-block'>
                            <SelectorLink to={item.buttonTarget} name={item.buttonText} />
                        </div>
                    )}
                </NumberedRow>
            </div>
        </BackgroundImage>
    )
}

const SelectorLink = ({ to, name }) => (
    <div className='d-inline-block bg-light me-3 mb-2 shadow'>
        <Link to={to} className={`btn btn-success rounded-0 spaced-light px-5 w-100 5`}>
            {name}
        </Link>
    </div>
)

export default NumberedSectionWithBg
